import React from "react"

import Footer from "../components/footer"
import Header from "../components/header"
export default () => {
  return (

  
<div class="min-h-screen bg-white mb-4">
  <Header/>

  <main class="overflow-hidden">

    <div class="bg-gray-50">
      <div class="py-24 lg:py-32">
        <div class="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
          <h1 class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">Jobss</h1>
          <p class="mt-6 text-xl text-gray-500 max-w-3xl">Vel nunc non ut montes, viverra tempor. Proin lectus nibh phasellus morbi non morbi. In elementum urna ut volutpat. Sagittis et vel et fermentum amet consequat.</p>
        </div>
      </div>
    </div>

    

  </main>

  <Footer/>
</div>
 
    
  )
}
